import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.css';
import i18n from './i18n/i18n';
import VueLazyload from 'vue-lazyload';
import VueMeta from 'vue-meta';
import dotenv from 'dotenv';
import AOS from 'aos';
import 'aos/dist/aos.css';
import VueProgressBar from 'vue-progressbar';
import Flicking from '@egjs/vue-flicking';
import '@egjs/vue-flicking/dist/flicking.css';
import VueGtag from 'vue-gtag';
import VueGtm from 'vue-gtm';

Vue.use(Flicking);

Vue.use(VueProgressBar, {
  color: '#fc7735',
  failedColor: 'red',
  height: '4px'
});

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: '/images/lz-img.png',
  loading: '/images/lz-img.png',
  attempt: 3
});
Vue.config.productionTip = false;

const envFile =
  process.env.NODE_ENV === 'production'
    ? '.env.production'
    : '.env.development';
dotenv.config({ path: envFile });

// Google Analytics & Google Tag Manager
Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_GA_ID ?? '' }
  },
  router
);
Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_ID ?? '',
  vueRouter: router
});

router.afterEach((to, from) => {
  const contentWrapper = document.querySelector('.v-content__wrap');
  if (
    (contentWrapper !== null && !to.query['category']) ||
    (from.name === 'Home' && to.query['category'])
  ) {
    contentWrapper.scrollTop = 0;
    window.scroll(0, 0);
  }
});

export const app = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  mounted() {
    AOS.init({ easing: 'ease-in', once: true });
  }
}).$mount('#app');
