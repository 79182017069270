<template>
  <div class="main-banner">
    <div class="title-banner col-11 col-sm-10">
      <div class="banner-renew">
        <div class="subtitle-banner" data-aos="fade-up">
          <div class="title-main">
            <h1>
              <p class="tgl-text-smart-speed">SMART &</p>
              <p class="tgl-text-smart-speed">SPEED</p>
              <p class="tgl-text-solutions">SOLUTIONS</p>
            </h1>
          </div>
          <span style="font-size: 16px; line-height: 1.5; margin-top: 10px">
            {{ banner.subtitleStart }}
            <b> {{ banner.subtitleBold }}</b
            ><br />
            {{ banner.subtitleEnd }}
          </span>
          <button
            type="button"
            class="mt-5 btn btn-primary text d-flex align-items-center btn-contact-us"
            @click="toContact"
          >
            {{ banner.btnContact }}
            <svg
              width="18"
              height="12"
              viewBox="0 0 20 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="services__item__detail-img"
            >
              <path
                d="M1.33301 7C0.780723 7 0.333008 7.44772 0.333008 8C0.333008 8.55228 0.780723 9 1.33301 9V7ZM19.0123 8.70711C19.4029 8.31658 19.4029 7.68342 19.0123 7.29289L12.6484 0.928932C12.2579 0.538408 11.6247 0.538408 11.2342 0.928932C10.8436 1.31946 10.8436 1.95262 11.2342 2.34315L16.891 8L11.2342 13.6569C10.8436 14.0474 10.8436 14.6805 11.2342 15.0711C11.6247 15.4616 12.2579 15.4616 12.6484 15.0711L19.0123 8.70711ZM1.33301 9H18.3052V7H1.33301V9Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <img alt="cad" :src="cad" class="element-tech cad" v-if="isDesktop" />
    <img alt="ai" :src="ai" class="element-tech ai" v-if="isDesktop" />
    <img alt="meta" :src="meta" class="element-tech meta" v-if="isDesktop" />
    <img alt="c" :src="c" class="element-tech c" v-if="isDesktop" />
    <img alt="aws" :src="aws" class="element-tech aws" v-if="isDesktop" />
    <img alt="react" :src="react" class="element-tech react" v-if="isDesktop" />
  </div>
</template>

<script>
import cad from '../../../public/images/icons/preload/cad.svg';
import ai from '../../../public/images/icons/preload/ai.svg';
import meta from '../../../public/images/icons/preload/meta.svg';
import c from '../../../public/images/icons/preload/c++.svg';
import code from '../../../public/images/icons/preload/tag-code.svg';
import aws from '../../../public/images/icons/preload/aws-white.svg';
import react from '../../../public/images/icons/preload/react-white.svg';
import { mapState } from 'vuex';

export default {
  name: 'Banner',
  data() {
    return {
      isDesktop: window.innerWidth >= 768,
      banner: this.$t('home').banner,
      cad,
      ai,
      meta,
      c,
      code,
      aws,
      react
    };
  },
  methods: {
    toContact() {
      this.$gtag.event('contact_us_click', {
        'event_category': "Banner Home Page",
        'event_label': "Contact Us Button",
      })
      this.$router.push('/contact')
    },
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang
    })
  },
  watch: {
    lang() {
      this.banner = this.$t('home').banner;
    }
  }
};
</script>

<style lang="scss" scroped>
.main-banner {
  background: url('/images/bg/bg-banner-1.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: left;
  background-position-y: center;
  height: 100vh;
  min-height: 900px;
  max-height: 1280px;
  width: 100%;
  font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 400;
  color: black;
  text-align: center;
  position: relative;
  overflow: hidden;

  @keyframes banner-pen-fly {
    from {
      transform: scale(0.7);
      top: 100%;
      left: -50%;
    }
    to {
      transform: scale(1);
      top: 12vh;
      left: -12vw;
    }
  }
}
.icon {
  width: 40px;
}
@keyframes spinIcon {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.circle {
  content: '';
  position: absolute;
  width: 150px;
  height: 150px;
  background: url('/images/bg/circle-slogan.svg');
  background-repeat: no-repeat;
  background-size: cover;
  top: -30px;
  left: -50px;
  // animation: loadCircle 2s forwards;
  animation-delay: 2s;
  opacity: 0;
  transform: scale(0);
}
@keyframes loadCircle {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.element-tech {
  max-width: 60px;
  position: absolute;
  animation: bunnyY 2s ease-in-out infinite;

  &.cad {
    top: 20%;
    right: 47%;
    opacity: 0.7;
    animation-delay: 0.2s;
  }
  &.ai {
    top: 30%;
    right: 12%;
    animation-delay: 0.3s;
  }
  &.meta {
    max-width: 80px;
    top: 10%;
    right: 5%;
    opacity: 0.5;
    animation-delay: 0.4s;
  }
  &.c {
    top: 10%;
    left: 60%;
    animation-delay: 0.5s;
  }
  &.code {
    max-width: 50px;
    top: 8%;
    right: 25%;
  }
  &.aws {
    max-width: 50px;
    bottom: 45%;
    right: 5%;
  }
  &.react {
    top: 37%;
    right: 43%;
  }
  @media (max-width: 1400px) {
    &.c {
      top: 30%;
      left: 60%;
    }
    &.cad {
      top: 12%;
      right: 45%;
    }
    &.meta {
      top: 12%;
      right: 5%;
    }
    &.aws,
    &.ai,
    &.react {
      display: none;
    }
  }
  @media (max-width: 768px) {
    max-width: 40px !important;
  }
}
.title-banner {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  z-index: 1;
  .banner-renew {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .penguin-jump {
      width: 400px;
      // animation: jumpUp 2s backwards;
    }
  }
  .subtitle-banner {
    width: 35%;
    text-align: left;
    // overflow: hidden;
    display: flex;
    flex-direction: column;
    span {
      font-size: 18px;
      font-weight: 400;
      // animation: slideUp 2s backwards;
      animation-delay: 2.5s;
      line-height: 22px;
      color: var(--grey-text);
      .text-highlight {
        color: var(--grey-text);
        font-weight: 500;
        font-style: italic;
        font-size: 18px;
      }
    }
  }
  .btn-contact {
    width: max-content;
    button {
      margin-top: 16px;
      background-color: #002a9c;
      padding: 12px 24px;
      border-radius: 50px;
      color: #fff;
      width: 200px;
      // animation: slideUp 1s backwards;
      animation-delay: 3s;
      span {
        font-size: 16px;
        animation: none;
        font-weight: 700;
      }
    }
  }
  .title-main {
    z-index: 1;
    h1 {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex-wrap: wrap;
      // animation: slideUp 2s backwards;
      animation-delay: 2s;
      p {
        line-height: 1;
        font-size: 55px;
        font-weight: 800;
        margin-bottom: 8px;
      }
    }
    span {
      font-size: 35px;
      font-weight: bold;
    }
    p {
      overflow: hidden;
      span {
        display: inline-block;
        // animation: slideUp 1s backwards;
      }
    }
    .tgl-text-smart-speed {
      background: -webkit-linear-gradient(
        90deg,
        rgba(7, 117, 239, 1) 0%,
        rgba(0, 7, 237, 1) 21%,
        rgba(0, 7, 237, 1) 51%,
        rgba(7, 117, 239, 0.5) 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      max-width: max-content;
    }
    .tgl-text-solutions {
      background: -webkit-linear-gradient(
        90deg,
        rgba(255, 115, 45, 1) 0%,
        rgba(255, 102, 25, 1) 21%,
        rgba(255, 102, 25, 1) 51%,
        rgba(255, 115, 45, 0.5) 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      max-width: max-content;
    }
  }

  .btn-contact-us {
    background: #fc7735;
    border-radius: 40px;
    padding: 10px 28px;
    width: max-content;
    border: none;
    gap: 8px;
  }

  .btn-contact-us:hover {
    opacity: 0.8;
    transition: 0.3s;
  }

  @keyframes slideUp {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0%);
      opacity: 1;
    }
  }
  .sub1 {
    animation-delay: 0.1s;
  }
  .sub2 {
    animation-delay: 0.3s;
  }
  .sub3 {
    animation-delay: 0.6s;
  }
}

@keyframes jumpUp {
  from {
    transform: translate(100%, 30%) scale(0.25);
  }
  to {
    transform: translate(0%, 0%) scale(1);
  }
}
@keyframes bunnyY {
  0% {
    transform: translate(0%, 0%);
  }
  50% {
    transform: translate(0%, 10%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}
@media (max-width: 1024px) {
  .circle {
    display: none;
    width: 80px;
    height: 80px;
    animation-delay: 0.2s;
    top: 10px;
    left: -20px;
  }
  .main-banner {
    min-height: 700px;
    background: url('/images/bg/bg-banner.svg');
    background-repeat: no-repeat;
    &::after {
      display: none;
    }
  }
  .element-tech {
    display: none !important;
  }
  .main-banner {
    max-height: 650px;
    background-size: 100%;
    background-position-y: top;
    background-position-x: center;
  }
  .title-banner {
    width: 50% !important;
    top: 60%;
    left: 35%;
    transform: translate(-50%, -50%);
    .banner-renew {
      margin-top: 50px;
    }

    .subtitle-banner {
      width: 100%;
      span {
        animation-delay: 0.2s;
        font-size: 18px !important;
      }
    }
    .title-main h1 {
      animation-delay: 0s;
      p {
        font-size: 42px;
      }
      span {
        font-size: 55px !important;
      }
    }
    .btn-contact button {
      animation-delay: 0.4s;
    }
    span {
      font-size: 24px !important;
    }
  }
}

@media (max-width: 768px) {
  .main-banner {
    max-height: 768px;
    background-size: 110%;
    background-position-y: top;
    background-position-x: center;
  }
  .title-banner {
    width: 100% !important;
    top: 64%;
    left: 50%;
    transform: translate(-50%, -50%);
    .banner-renew {
      margin-top: 55px;
    }
    .subtitle-banner {
      width: 100%;
      span {
        font-size: 18px !important;
      }
    }
    .title-main h1 {
      p {
        font-size: 40px;
      }
      span {
        font-size: 55px !important;
      }
    }
    span {
      font-size: 24px !important;
    }
  }
}
@media (max-width: 560px) {
  .main-banner {
    background-size: 140%;
    min-height: 786px;
    max-height: none;
  }
  .title-banner {
    top: 60%;
  }
}
</style>
